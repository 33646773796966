import React from 'react';
import greenCheckIcon from '../assets/images/Green-Checkmark-icon.svg';
import yellowCheckIcon from '../assets/images/O-check-mark.svg';
import redCheckIcon from '../assets/images/X-check-mark.svg';

const GreencheckIcon = () => (
    <img title='Inbegrepen' className='green-check-icon' src={greenCheckIcon} alt="" loading='lazy' />
);

const YellowcheckIcon = () => (
    <img title='Optioneel' className='green-check-icon' src={yellowCheckIcon} alt="" loading='lazy' />
);

const YellowGreencheckIcon = () => (
    <span title='Optioneel/Inbegrepen. Afhankelijk van je keuze van module'>
        <img className='green-check-icon' src={yellowCheckIcon} alt="" loading='lazy' />
        <img className='green-check-icon' src={greenCheckIcon} alt="" loading='lazy' />
    </span>
);

const RedcheckIcon = () => (
    <img title='Niet inbegrepen' className='green-check-icon' src={redCheckIcon} alt="" loading='lazy' />
);

export const marketing = [
    { id: 1, name: "Marketing", start: [<RedcheckIcon />], professional: [<YellowcheckIcon />], business: [<YellowGreencheckIcon/>], enterprise: [<GreencheckIcon />], bold: true},
    { id: 2, name: "Met de volgende functies:", start: "", professional: "", business: "", enterprise: "", bold: true},
    { id: 3, name: "E-mailmarketing", start: "", professional: "", business: "", enterprise: "", extrainfo: ["Overzicht alle aanmeldingen per nieuwsbrief", "Personaliseren e-mail", "Preview en test e-mail", "Gefaseerde verzending i.v.m. spam-filters", "Aan-of afmelden nieuwsbrief vanuit TravelSpirit"]},
    { id: 4, name: "Klantreviews", start: "", professional: "", business: "", enterprise: "", extrainfo: ["Tonen TrustPilot blokje in contact TravelSpirit", "Zichtbaarheid aantal door reiziger toegekende sterren", "Zichtbaarheid door reiziger achtergelaten review(s)", "Mogelijkheid versturen TrustPilot uitnodiging direct vanuit TravelSpirit"]},
    { id: 5, name: "Klantsegmentatie", start: "", professional: "", business: "", enterprise: "", extrainfo: ["Automatisch ophalen Whize segment bij Whooz", "Automatisch ophalen Whize subsegment bij Whooz", "Automatisch aanmaken labels in het CRM"]}
];