import { graphql, Link } from 'gatsby';
import React, { useState } from "react";
import SEO from "../components/seo"
import Layout from "../components/layout";
import Star from '@material-ui/icons/Star';
import stepBox01 from '../assets/images/step1.svg';
import stepBox02 from '../assets/images/step2.svg';
import stepBox03 from '../assets/images/step3.svg';
import stepBox04 from '../assets/images/step4.svg';
import regelStapple from '../assets/images/regel-stapple.svg';
import stepArrow from '../assets/images/step-arrow.png';
import { VisibilityOff, Visibility } from '@material-ui/icons';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import "./pricing.scss";
import { backoffice } from '../pricing-features/backoffice';
import { visualtourbuilder } from '../pricing-features/visualtourbuilder';
import { financeAccounting } from '../pricing-features/finance-Accounting';
import { vluchten } from '../pricing-features/vluchten';
import { website } from '../pricing-features/website';
import { marketing } from '../pricing-features/marketing';
import { testimonials } from '../pricing-features/testimonials';
import addonVideo from '../assets/videos/pricing_page_addon.mp4';
import { Tooltip } from '@material-ui/core';

export default ({ data }) => {

    const [activeTab, setActiveTab] = useState(0);
    const [visibleItems, setVisibleItems] = useState({});
    const showMoreVisibility = (id) => {
        setVisibleItems((prev) => ({
          ...prev,
          [id]: !prev[id],
        }));
    };

    const getTooltip = (item) => {
        if(item.length === 1) {
            if(item[0].type !== undefined && item[0].type.name !== undefined) {
                const name = item[0].type.name;
                switch (name) {
                    case 'GreencheckIcon':
                        return 'Inbegrepen';
                    case 'YellowcheckIcon':
                        return 'Optioneel';
                    case 'RedcheckIcon':
                        return 'Niet inbegrepen';
                    default:
                        return '';
                }
            } else return item[0];
        } else {
            return 'Afhankelijk van je keuze van module.';
        }
    };

    const features = [
        { id: 0, name: 'backoffice', data: backoffice },
        { id: 1, name: 'visualtourbuilder', data: visualtourbuilder },
        { id: 2, name: 'financeAccounting', data: financeAccounting },
        { id: 3, name: 'vluchten', data: vluchten },
        { id: 4, name: 'website', data: website },
        { id: 5, name: 'marketing', data: marketing },
    ];

    const handleTabClick = (index) => {
        setActiveTab(index);
    };

    const ConditionalLabel = ({ id, category }) => {
        const [isVisible, setIsVisible] = useState(false);
        const toggleVisibility = () => {
            setIsVisible(!isVisible);
        };
        var items = [];
        if (category === "backoffice") items = backoffice.find(item => item.id === id);
        else if (category === "visualtourbuilder") items = visualtourbuilder.find(item => item.id === id);
        else if (category === "financeAccounting") items = financeAccounting.find(item => item.id === id);
        else if (category === "vluchten") items = vluchten.find(item => item.id === id);
        else if (category === "website") items = website.find(item => item.id === id);
        else if (category === "marketing") items = marketing.find(item => item.id === id);
        return (
            <span>
                {(items && items.extrainfo) ? (
                    <>
                        <div className="toggle-button-wrap">
                            <div className="toggle-button" onClick={toggleVisibility}>
                                <span className="text-left">{items.name}</span>
                                <span className='visibility'>{isVisible ? <VisibilityOff /> : <Visibility />}</span>
                            </div>
                            {isVisible && (
                                <ul>
                                    {(items.extrainfo).map((item, index) => (
                                        <li key={index}>{item}</li>
                                    ))}
                                </ul>
                            )}
                        </div>
                    </>
                ) : (
                    items.name
                )}
            </span>
        );
    };


    return (
        <Layout>
            <SEO title="Pricing met TravelSpirit" urlParams="pricing" />

            <div className="pricing-page-full">
                <div className="pricing-page-container">
                    <div className="pricing-top-banner">
                        <h1>Travelsoftware voor elke stap</h1>
                        <p>Ontdek welk abonnement het beste bij jou past</p>
                    </div>
                    <div className="pricing-card-container">
                        <div className="pricing-card-wrap">
                            <div className="pricing-card-top">
                                <h2>Start</h2>
                            </div>
                            <div className="pricing-card-content">
                                <div className="pricing-card-price">v.a. €99<span>/p.m.</span></div>
                                <p className="pricing-card-paragraph">Voor startende en kleinschalige
                                    reisondernemers</p>

                                <ul className="pricing-card-list">
                                    <li>Inclusief 1 gebruiker</li>
                                    <li>Complete Backoffice:
                                        <ul>
                                            <li>Customer Relationship Management</li>
                                            <li>Workflow Management</li>
                                            <li>Facturatie</li>
                                            <li>Productbeheer</li>
                                        </ul>
                                    </li>
                                    <li>Optioneel: VTB offertetool
                                        + Finance & Accounting
                                    </li>
                                </ul>
                                <a href='#feature-block' className="pricing-button">
                                    Bekijk features
                                </a>
                            </div>
                        </div>
                        <div className="pricing-card-wrap">
                            <div className="pricing-card-top">
                                <h2>Professional</h2>
                            </div>
                            <div className="pricing-card-content">
                                <div className="pricing-card-price">v.a. €279<span>/p.m.</span></div>
                                <p className="pricing-card-paragraph">Voor middelgrote reisorganisaties die efficiënter willen werken</p>

                                <ul className="pricing-card-list">
                                    <li>Inclusief 3 gebruikers</li>
                                    <li>Alles uit Start + keuze om modulair uit te breiden</li>
                                    <li>Upgrade met add on’s</li>
                                </ul>
                                <a href='#feature-block' className="pricing-button">
                                    Bekijk features
                                </a>
                            </div>
                        </div>
                        <div className="pricing-card-wrap business-card-wrap">
                            <div className="pricing-card-top">
                                <h2>Business</h2>
                            </div>
                            <div className="pricing-card-content">
                                <div className="pricing-card-price">v.a. €899<span>/p.m.</span></div>
                                <p className="pricing-card-paragraph">De totaaloplossing voor middelgrote en grote reisorganisaties</p>

                                <ul className="pricing-card-list">
                                    <li>Inclusief 10 gebruikers</li>
                                    <li>Alles van Professional + incl. 3 modules naar keuze</li>
                                    <li>Upgrade met add on’s</li>
                                </ul>
                                <a href='#feature-block' className="pricing-button">
                                    Bekijk features
                                </a>
                            </div>
                        </div>
                        <div className="pricing-card-wrap">
                            <div className="pricing-card-top">
                                <h2>Enterprise</h2>
                            </div>
                            <div className="pricing-card-content">
                                <div className="pricing-card-price">Op aanvraag</div>
                                <p className="pricing-card-paragraph">Een maatwerk totaaloplossing voor grote reisorganisaties</p>

                                <ul className="pricing-card-list">
                                    <li>Vanaf 30 gebruikers</li>
                                    <li>Compleet pakket met alle modules van TravelSpirit</li>
                                    <li>Add on’s naar keuze</li>
                                </ul>
                                <a href='#feature-block' className="pricing-button">
                                    Bekijk features
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="plan-page-container">
                <div className="plan-banner">
                    <h2>Plan een demo of verkennend gesprek</h2>
                    <p>Meer weten? Wij gaan graag met je in gesprek om de mogelijkheden te bespreken om
                        <br />je verder te helpen.</p>
                </div>
                <div className="plan-button-full">
                    <ul className="button-menu">
                        <li className="demo-button-container">
                            <button className="demo-button">
                                <Link id="demo-container-button" key="demo-button" to={`/demo`}>Demo inplannen</Link>
                            </button>
                            <p>Online sessie van 30 minuten</p>
                        </li>
                        <li className="more-about-container">
                            <button id="meet-container-button" className="more-about-button">
                                <Link key="meet-button" to={`/demo-afspraak`}>Verkennend gesprek</Link>
                            </button>
                            <p>Stel online al jouw vragen</p>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="step-page-full">
                <div className="step-page-container">
                    <div className="step-banner">
                        <h2>Regel je travelsoftware in <span>4 stappen</span></h2>
                        <div className="step-top-arrow">
                            <img src={stepArrow} alt="" />
                        </div>
                        <p>We helpen je graag de meest geschikte optie te vinden</p>
                    </div>
                    <div className="step-content">
                        <div className="step-box step-first">
                            <div className="step-box-image">
                                <img src={stepBox01} alt="" />
                            </div>
                            <div className="step-stepple">
                                <img src={regelStapple} alt="" />
                            </div>
                            <div className="step-box-text">
                                <h3>Kies je licentie</h3>
                                <ul>
                                    <li>Start</li>
                                    <li>Professional</li>
                                    <li>Business</li>
                                    <li>Enterprise</li>
                                </ul>
                            </div>
                        </div>
                        <div className="step-box step-second">
                            <div className="step-box-image">
                                <img src={stepBox02} alt="" />
                            </div>
                            <div className="step-stepple">
                                <img src={regelStapple} alt="" />
                            </div>
                            <div className="step-box-text">
                                <h3>Kies je modules</h3>
                                <ul>
                                    <li>Back Office</li>
                                    <li>Visual Tour Builder</li>
                                    <li>Finance & Accounting</li>
                                    <li>Vluchten</li>
                                    <li>Website (SPoE)</li>
                                    <li>(eMail) Marketing</li>
                                </ul>

                            </div>
                        </div>
                        <div className="step-box step-third">
                            <div className="step-box-image">
                                <img src={stepBox03} alt="" />
                            </div>
                            <div className="step-middle-arrow">
                                <a href='#addon-block'>En nog veel meer</a>
                                <img src={stepArrow} alt="" />
                            </div>
                            <div className="step-stepple">
                                <img src={regelStapple} alt="" />
                            </div>
                            <div className="step-box-text">
                                <h3>Kies je add on’s</h3>
                                <ul>
                                    <li>Meertalige PDF output</li>
                                    <li>Mobiele app</li>
                                    <li>Boekbare website</li>
                                    <li>Automations</li>
                                </ul>
                            </div>
                        </div>
                        <div className="step-box step-fourth">
                            <div className="step-box-image">
                                <img src={stepBox04} alt="" />
                            </div>
                            <div className="step-box-text">
                                <h3>Kies je support niveau</h3>
                                <ul>
                                    <li>Support uren</li>
                                    <li>Responstijd</li>
                                    <li>Telefonische support</li>
                                </ul>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div id="feature-block" className="feature-page-container">
                <div className="feature-banner">
                    <h2>Features</h2>
                    <p>Met welke modules ga jij aan de slag?</p>
                </div>
                <div className="tabs-container">
                    {features.map((feature, index) => (
                        <button key={feature.id} className={activeTab===index ? 'active' : '' } onClick={()=> handleTabClick(index)}>
                            {feature.id === 0 && 'Back Office'}
                            {feature.id === 1 && 'Visual Tour Builder'}
                            {feature.id === 2 && 'Finance & Accounting'}
                            {feature.id === 3 && 'Vluchten'}
                            {feature.id === 4 && 'Website (SPoE)'}
                            {feature.id === 5 && 'Marketing'}
                        </button>
                    ))}
                </div>
                <div className="feature-table table-responsive">
                    <table className="table">
                        <thead>
                            <tr>
                                <th>&nbsp;</th>
                                <th className="text-center">Start</th>
                                <th className="text-center">Professional</th>
                                <th className="text-center">Business</th>
                                <th className="text-center">Enterprise</th>
                            </tr>
                        </thead>
                        <tbody>
                            {features[activeTab].data.map((item, index) => (
                            <tr key={`${activeTab}${item.id}${index}`} id={`${activeTab}${item.id}${index}`}>
                                <td className={item.bold ? "font-weight-bold" : "" }>
                                    <ConditionalLabel id={item.id} category={features[activeTab].name} />
                                </td>
                                <td valign="top" className="text-center">
                                    {item.start != null && Array.isArray(item.start) && item.start.length > 0 ? (
                                        <Tooltip title={getTooltip(item.start)}>
                                            <span style={{ display: 'inline-flex', gap: '4px' }}>
                                                {item.start.map((starticon, index1) => (
                                                    <span key={`${index}-${index1}`}>
                                                        {starticon}
                                                    </span>
                                                ))}
                                            </span>
                                        </Tooltip>
                                    ) : (
                                        <span></span>
                                    )}
                                </td>
                                <td valign="top" className="text-center">
                                    {item.professional != null && Array.isArray(item.professional) && item.professional.length > 0 ? (
                                        <Tooltip title={getTooltip(item.professional)}>
                                            <span style={{ display: 'inline-flex', gap: '4px' }}>
                                                {item.professional.map((professionalicon, index2) => (
                                                    <span key={`${index}-${index2}`}>
                                                        {professionalicon}
                                                    </span>
                                                ))}
                                            </span>
                                        </Tooltip>
                                    ) : (
                                        <span></span>
                                    )}
                                </td>
                                <td valign="top" className="text-center">
                                    {item.business != null && Array.isArray(item.business) && item.business.length > 0 ? (
                                        <Tooltip title={getTooltip(item.business)}>
                                            <span style={{ display: 'inline-flex', gap: '4px' }}>
                                                {item.business.map((businessicon, index3) => (
                                                    <span key={`${index}-${index3}`}>
                                                        {businessicon}
                                                    </span>
                                                ))}
                                            </span>
                                        </Tooltip>
                                    ) : (
                                        <span></span>
                                    )}
                                </td>
                                <td valign="top" className="text-center">
                                    {item.enterprise != null && Array.isArray(item.enterprise) && item.enterprise.length > 0 ? (
                                        <Tooltip title={getTooltip(item.enterprise)}>
                                            <span style={{ display: 'inline-flex', gap: '4px' }}>
                                                {item.enterprise.map((enterpriseicon, index4) => (
                                                    <span key={`${index}-${index4}`}>
                                                        {enterpriseicon}
                                                    </span>
                                                ))}
                                            </span>
                                        </Tooltip>
                                    ) : (
                                        <span></span>
                                    )}
                                </td>
                            </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
            <div id="addon-block" className="addon-page-container">
                <div className="addon-banner">
                    <h2>Voeg nog meer waarde toe met add on’s</h2>
                    <p>Verhoog je service met extra's op maat</p>
                </div>
                <div className="addon-content">
                    <div className="addon-content-left">
                        <div className="addon-videowrapeer">
                            <video autoPlay muted loop width="800" height="300">
                                <source src={addonVideo} />
                            </video>
                        </div>
                    </div>
                    <div className="addon-content-right">
                        <div className="addon-button">
                            <ul className="addon-ul">
                                <li>Mobiele app</li>
                                <li>Meertalige PDF output</li>
                                <li>Visual Tour Builder - Agent tool</li>
                                <li>Allotment & Sales</li>
                                <li>Custom Reporting</li>
                                <li>Audit logging</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="addon-paragraph">
                    <p>
                        Zie je de gewenste add on er niet bij staan? Er is nog veel meer!<br />
                        Plan een demo of verkennend gesprek in om alle mogelijkheden te ontdekken.
                    </p>
                </div>
                <div className="step-top-arrow">
                    <img src={stepArrow} alt="" />
                </div>
            </div>
            <div className="plan-page-full">
                <div className="plan-page-container">
                    <div className="plan-banner">
                        <h2>Ga aan de slag met jouw Customer Journey!</h2>
                        <p>Bespaar tijd en geld én verbeter je effectiviteit. Onze oplossingen kun je stap voor stap in de gehele<br />
                            Customer Journey inzetten. Benieuwd hoe?</p>
                    </div>
                    <div className="plan-button-full">
                        <ul className="button-menu">
                            <li className="demo-button-container">
                                <button className="demo-button">
                                    <Link id="demo-container-button" key="demo-button" to={`/demo`}>Demo inplannen</Link>
                                </button>
                                <p className="white">Online sessie van 30 minuten</p>
                            </li>
                            <li className="more-about-container">
                                <button id="meet-container-button" className="more-about-button">
                                    <Link key="meet-button" to={`/demo-afspraak`}>Verkennend gesprek</Link>
                                </button>
                                <p className="white">Stel online al jouw vragen</p>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="testimonial-page-container">
                <div className="testimonial-banner">
                    <h2>Wat onze klanten zeggen</h2>
                </div>
                <div className="testimonial-content">
                    {testimonials.map(testimonial => (
                        <div className="testimonial-item" key={testimonial.id}>
                            <div className="testimonial-image">
                                {testimonial.logo}
                            </div>
                            <div className="testimonial-text">
                                <span className="testimonial-rating-image">
                                    {Array.from({ length: (testimonial.star) }, (_, index) => (
                                        <Star key={index} />
                                    ))}
                                </span>
                                <h4>{testimonial.title}</h4>
                                <p>
                                    {visibleItems[testimonial.id] ? testimonial.content : `${testimonial.content.slice(0, 134)}...`}
                                </p>
                                <button onClick={() => showMoreVisibility(testimonial.id)}>
                                    {visibleItems[testimonial.id] ? 'Lees minder' : 'Lees meer'}
                                </button>
                                <div className='testimonial-signature'>
                                    <p>{testimonial.signature}</p>
                                    <a href={testimonial.link} target='_blank' rel="noreferrer">{testimonial.display_link}</a>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </Layout>
    )
}

export const query = graphql`
    query {
        gcms {
            metaDescriptions {
                indicator
                value
            }
            titles {
                indicator
                value
                richValue { html }
            }
        }
        allDataJson {
            edges {
                node {
                    partners {
                        class
                        url
                        name
                        site
                    }
                }
            }
        }
    }
`;