import React from 'react';
import testimonialLogobeiberia from '../assets/images/beiberia.jpeg';
import testimonialLogopreferredlogo from '../assets/images/preferredlogo.jpeg';

const TestimonialLogobeiberia = () => (
    <img className='green-check-icon' src={testimonialLogobeiberia} alt="" loading='lazy' />
);

const TestimonialLogopreferredlogo = () => (
    <img className='green-check-icon' src={testimonialLogopreferredlogo} alt="" loading='lazy' />
);

export const testimonials = [
    { id: 1, logo: <TestimonialLogopreferredlogo />, star: 5, title: "Een fijne partij", content: "“Preferred Hotel Reservations werkt sinds 2023 met de software van TravelSpirit en heeft daardoor veel efficiency behaalt in het hele boekings- en facturatieproces. Het proces van A tot Z is beter controleer- en traceerbaar, waardoor het team goede focus kan behouden en eenvoudig inzichten kan verkrijgen. De samenwerking met het team van TravelSpirit is zeer prettig, van de begeleiding tijdens de implementatie tot de support die op zijn tijd nodig is. Kortom: een fijne partij om mee samen te werken! ”", signature: "-Preferred", link: "http://www.preferred.nl/", display_link: "www.preferred.nl"},
    { id: 2, logo: <TestimonialLogobeiberia />, star: 5, title: "Alle mailboxen georganiseerd", content: "“Het grootste voordeel voor mij is dat alle mailboxen georganiseerd zijn. Geen ellenlange rijen van submapjes, maar alles is binnen 5 seconden op één plek te vinden. Zo heb je voor een reis een duidelijk overzicht van wat er gaande is, zowel met de leveranciers als de reizigers.”", signature: "-Be iberia", link: "https://www.beiberia.com/", display_link: "www.beiberia.com"},
];