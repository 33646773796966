import React from 'react';
import greenCheckIcon from '../assets/images/Green-Checkmark-icon.svg';
import yellowCheckIcon from '../assets/images/O-check-mark.svg';
import redCheckIcon from '../assets/images/X-check-mark.svg';

const GreencheckIcon = () => (
    <img title='Inbegrepen' className='green-check-icon' src={greenCheckIcon} alt="" loading='lazy' />
);

const YellowcheckIcon = () => (
    <img title='Optioneel' className='green-check-icon' src={yellowCheckIcon} alt="" loading='lazy' />
);

const RedcheckIcon = () => (
    <img title='Niet inbegrepen' className='green-check-icon' src={redCheckIcon} alt="" loading='lazy' />
);

export const backoffice = [
    { id: 1, name: "Back Office", start: [<GreencheckIcon />], professional: [<GreencheckIcon />], business: [<GreencheckIcon />], enterprise: [<GreencheckIcon />], bold: true},
    { id: 2, name: "Customer Relationship Management", start: [<GreencheckIcon />], professional: [<GreencheckIcon />], business: [<GreencheckIcon />], enterprise: [<GreencheckIcon />], extrainfo: [ "Contactgegevens", "Type contacten", "Notities logboek", "FInanciële informatie", "Export mogelijkheden"]},
    { id: 3, name: "Lijsten (tbv exporteren contacten)", start: [<GreencheckIcon />], professional: [<GreencheckIcon />], business: [<GreencheckIcon />], enterprise: [<GreencheckIcon />]},
    { id: 4, name: "Automation: website contactformulier importeren in CRM", start: [<GreencheckIcon />], professional: [<GreencheckIcon />], business: [<GreencheckIcon />], enterprise: [<GreencheckIcon />]},
    { id: 5, name: "Telefonie (VOIP)", start: [<GreencheckIcon />], professional: [<GreencheckIcon />], business: [<GreencheckIcon />], enterprise: [<GreencheckIcon />], extrainfo: ["Tonen gemiste gesprekken", "Weergeven gespreksduur en periode in de wacht", "Mogelijkheid koppelen gesprek aan bestaand contact", "Bijhouden gespreksnotities", "Gesprekken opnemen"]},
    { id: 6, name: "E-mails", start: [<GreencheckIcon />], professional: [<GreencheckIcon />], business: [<GreencheckIcon />], enterprise: [<GreencheckIcon />], extrainfo: ["Versturen e-mails vanuit TravelSpirit", "Automatische Mailacties","Uitgebreid zoekfilter", "Toekennen prioriteit", "E-mail templates aanmaken"]},
    { id: 7, name: "E-mail templates met dynamische variabelen", start: [<GreencheckIcon />], professional: [<GreencheckIcon />], business: [<GreencheckIcon />], enterprise: [<GreencheckIcon />]},
    { id: 8, name: "To Do's (workflow management)", start: [<GreencheckIcon />], professional: [<GreencheckIcon />], business: [<GreencheckIcon />], enterprise: [<GreencheckIcon />], extrainfo: ["Workflow op basis van vertrek-, betaal of contractdatum", "Datum triggert automatisch een reeks taken", "Rekening houdend met het weekend", "Exporteren takenlijst op basis van filter", "Mogelijkheid aanmaken taak bij archiveren andere taak"]},
    { id: 9, name: "Inplannen Automatische Workflow", start: [<GreencheckIcon />], professional: [<GreencheckIcon />], business: [<GreencheckIcon />], enterprise: [<GreencheckIcon />]},
    { id: 10, name: "Inkoop bestellingen", start: [<GreencheckIcon />], professional: [<GreencheckIcon />], business: [<GreencheckIcon />], enterprise: [<GreencheckIcon />]},
    { id: 11, name: "Inkoop kostprijzen", start: [<GreencheckIcon />], professional: [<GreencheckIcon />], business: [<GreencheckIcon />], enterprise: [<GreencheckIcon />]},
    { id: 12, name: "Documenten", start: [<GreencheckIcon />], professional: [<GreencheckIcon />], business: [<GreencheckIcon />], enterprise: [<GreencheckIcon />], extrainfo: ["Uploaden, downloaden en koppelen bestanden aan contacten", "Beheren Word templates", "Opslaan Travelplan output en koppelen aan contactpersoon", "Overzicht van alle documenten", "Filteren op datum en gebruiker"]},    
    { id: 13, name: "Travelplans (dossier opbouwen) & Travelplan Templates (pakketten)", start: [<GreencheckIcon />], professional: [<GreencheckIcon />], business: [<GreencheckIcon />], enterprise: [<GreencheckIcon />], extrainfo: ["Product Informatie Management (PIM)", "Aanmaken van producten/elementen (hotel, carrental, excursie, etc.)", "Contractbeheer"]},
    { id: 14, name: "Producten, basis prijzen calculeren, product content", start: [<GreencheckIcon />], professional: [<GreencheckIcon />], business: [<GreencheckIcon />], enterprise: [<GreencheckIcon />], extrainfo: ["Prijs calculeren", "Betaallinks aanmaken en versturen"]},
    { id: 15, name: "Travelplan cockpit", start: [<GreencheckIcon />], professional: [<GreencheckIcon />], business: [<GreencheckIcon />], enterprise: [<GreencheckIcon />]},
    { id: 16, name: "Creeren van PDF documenten", start: ["1 document"], professional: ["2 documenten"], business: ["3 documenten"], enterprise: ["5 documenten"]},
    { id: 17, name: "Vijf standaard rapportages (Excel export)", start: [<GreencheckIcon />], professional: [<GreencheckIcon />], business: [<GreencheckIcon />], enterprise: [<GreencheckIcon />]},
    { id: 18, name: "Betaallink emailen & betaling inlezen (excl. PSP account)", start: [<GreencheckIcon />], professional: [<GreencheckIcon />], business: [<GreencheckIcon />], enterprise: [<GreencheckIcon />]},
    { id: 19, name: "Annulerings- en reisverzekeringen berekenen", start: [<GreencheckIcon />], professional: [<GreencheckIcon />], business: [<GreencheckIcon />], enterprise: [<GreencheckIcon />]},
    { id: 20, name: "Annuleringskosten berekenen + nota", start: [<GreencheckIcon />], professional: [<GreencheckIcon />], business: [<GreencheckIcon />], enterprise: [<GreencheckIcon />]},
    { id: 21, name: "Terugkerende facturatie", start: [<GreencheckIcon />], professional: [<GreencheckIcon />], business: [<GreencheckIcon />], enterprise: [<GreencheckIcon />], extrainfo: ["Gemakkelijk omzeten van status (offerte, boeking, factuur etc)", "Automatische berekening van betalingen", "Betaallinks versturen"]},
    { id: 22, name: "Commissie facturatie", start: [<GreencheckIcon />], professional: [<GreencheckIcon />], business: [<GreencheckIcon />], enterprise: [<GreencheckIcon />]},
    { id: 23, name: "Fotobeheer (MediaSpirit)", start: ["10.000 foto's"], professional: ["10.000 foto's"], business: ["50.000 foto's"], enterprise: ["150.000 foto's"], extrainfo: ["Afbeeldingen in verschillende formaten", "Tags toevoegen aan afbeeldingen", "Cognitive tagging", "Zoekfilter afbeeldingen", "Bulkopties uitvoeren"]},
    { id: 24, name: "Grafisch ontwerp creditnota & (proforma) factuur PDF", start: [<YellowcheckIcon />], professional: [<YellowcheckIcon />], business: [<YellowcheckIcon />], enterprise: [<YellowcheckIcon />]},
    { id: 25, name: "Grafisch ontwerp reisbescheiden of vouchers PDF", start: [<YellowcheckIcon />], professional: [<YellowcheckIcon />], business: [<YellowcheckIcon />], enterprise: [<YellowcheckIcon />]},
    { id: 26, name: "Aanmaningen & batch emails versturen", start: [<GreencheckIcon />], professional: [<GreencheckIcon />], business: [<GreencheckIcon />], enterprise: [<GreencheckIcon />]},
    { id: 27, name: "Business Intelligence (PowerBI en Looker Studio)", start: [<GreencheckIcon />], professional: [<GreencheckIcon />], business: [<GreencheckIcon />], enterprise: [<GreencheckIcon />], extrainfo: ["Conversie per medewerker, bestemming, leverancier of kwartaal", "Marge per afdeling kantoor of maandCash Flow prognose inclusief uitgaven aan kantoorkosten zoals salarissen & overhead", "Productiviteit & KPI's"]},
    { id: 28, name: "Meerdere afdelingen", start: [<RedcheckIcon />], professional: [<GreencheckIcon />], business: [<GreencheckIcon />], enterprise: [<GreencheckIcon />]},
    { id: 29, name: "Groepsreizen (reizen op inschrijving)", start: [<RedcheckIcon />], professional: [<GreencheckIcon />], business: [<GreencheckIcon />], enterprise: [<GreencheckIcon />]},
    { id: 30, name: "Passolution (inreis, visum & vaccinatieinfo genereren, excl licentie)", start: [<RedcheckIcon />], professional: [<GreencheckIcon />], business: [<GreencheckIcon />], enterprise: [<GreencheckIcon />]},
    { id: 31, name: "Instant Messaging incl SMS (Respond.io)", start: [<RedcheckIcon />], professional: [<GreencheckIcon />], business: [<GreencheckIcon />], enterprise: [<GreencheckIcon />]},
    { id: 32, name: "STO Garant", start: [<RedcheckIcon />], professional: [<GreencheckIcon />], business: [<GreencheckIcon />], enterprise: [<GreencheckIcon />]},
    { id: 33, name: "Slack (notifications & mentions)", start: [<RedcheckIcon />], professional: [<GreencheckIcon />], business: [<GreencheckIcon />], enterprise: [<GreencheckIcon />]},
    { id: 34, name: "Gesprek automatisch doorverbinden (excl licenties)", start: [<RedcheckIcon />], professional: [<GreencheckIcon />], business: [<GreencheckIcon />], enterprise: [<GreencheckIcon />]},
    { id: 35, name: "VOIP audio opnames terugluisteren (excl licenties)", start: [<RedcheckIcon />], professional: [<RedcheckIcon />], business: [<GreencheckIcon />], enterprise: [<GreencheckIcon />]},
];