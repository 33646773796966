import React from 'react';
import greenCheckIcon from '../assets/images/Green-Checkmark-icon.svg';
import yellowCheckIcon from '../assets/images/O-check-mark.svg';
import redCheckIcon from '../assets/images/X-check-mark.svg';

const GreencheckIcon = () => (
    <img title='Inbegrepen' className='green-check-icon' src={greenCheckIcon} alt="" loading='lazy' />
);

const YellowcheckIcon = () => (
    <img title='Optioneel' className='green-check-icon' src={yellowCheckIcon} alt="" loading='lazy' />
);

const YellowGreencheckIcon = () => (
    <span title='Optioneel/Inbegrepen. Afhankelijk van je keuze van module'>
        <img className='green-check-icon' src={yellowCheckIcon} alt="" loading='lazy' />
        <img className='green-check-icon' src={greenCheckIcon} alt="" loading='lazy' />
    </span>
);

const RedcheckIcon = () => (
    <img title='Niet inbegrepen' className='green-check-icon' src={redCheckIcon} alt="" loading='lazy' />
);

export const vluchten = [
    { id: 1, name: "Vluchten", start: [<RedcheckIcon />], professional: [<YellowcheckIcon />], business: [<YellowGreencheckIcon />], enterprise: [<GreencheckIcon />], both: true, bold: true},
    { id: 2, name: "Met de volgende functies:", start: "", professional: "", business: "", enterprise: "", bold: true},
    { id: 3, name: "Importeren vluchten (GDS, Airtrade, Consolidair)", start: "", professional: "", business: "", enterprise: ""},
    { id: 4, name: "Afrekening inlezen (IATA HOT, Airtrade)", start: "", professional: "", business: "", enterprise: ""},
    { id: 5, name: "Koppeling met Fairfinder", start: "", professional: "", business: "", enterprise: ""}
];